/* eslint-disable max-lines */

import type { Slide } from '@/types/slides'

export const layouts: Slide[] = [
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: '4cbRxp',
        left: 0,
        top: 200,
        width: 546,
        height: 362.5,
        viewBox: [200, 200],
        path: 'M 0 0 L 0 200 L 200 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        opacity: 0.7,
        rotate: 0
      },
      {
        type: 'shape',
        id: 'ookHrf',
        left: 0,
        top: 0,
        width: 300,
        height: 320,
        viewBox: [200, 200],
        path: 'M 0 0 L 0 200 L 200 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        flipV: true,
        rotate: 0
      },
      {
        type: 'text',
        id: 'AkIh3E',
        left: 355,
        top: 95.11111111111111,
        width: 585,
        height: 116,
        lineHeight: 1.2,
        content: '<p style=\'\'><strong><span style=\'font-size: 80px\'>输入标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        wordSpace: 6
      },
      {
        type: 'text',
        id: '7stmVP',
        left: 355,
        top: 253.25,
        width: 585,
        height: 56,
        content: '<p><span style=\'font-size:  24px\'>请在此处输入副标题</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      },
      {
        type: 'line',
        id: 'FnpZs4',
        left: 361,
        top: 238,
        start: [0, 0],
        end: [549, 0],
        points: ['', ''],
        color: '{{themeColor}}',
        style: 'solid',
        width: 2,
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'ptNnUJ',
        left: 145,
        top: 148,
        width: 711,
        height: 77,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: 'mRHvQN',
        left: 207.50000000000003,
        top: 249.84259259259264,
        width: 585,
        height: 56,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处添加副标题</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'line',
        id: '7CQDwc',
        left: 323.09259259259267,
        top: 238.33333333333334,
        start: [0, 0],
        end: [354.8148148148148, 0],
        points: ['', ''],
        color: '{{themeColor}}',
        style: 'solid',
        width: 4
      }, 
      {
        type: 'shape',
        id: '09wqWw',
        left: -27.648148148148138,
        top: 432.73148148148147,
        width: 1056.2962962962963,
        height: 162.96296296296296,
        viewBox: [200, 200],
        path: 'M 0 20 C 40 -40 60 60 100 20 C 140 -40 160 60 200 20 L 200 180 C 140 240 160 140 100 180 C 40 240 60 140 0 180 L 0 20 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'vSheCJ',
        left: 183.5185185185185,
        top: 175.5092592592593,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0
      }, 
      {
        type: 'shape',
        id: 'Mpwv7x',
        left: 211.29629629629628,
        top: 201.80555555555557,
        width: 605.1851851851851,
        height: 185.18518518518516,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        opacity: 0.7
      }, 
      {
        type: 'text',
        id: 'WQOTAp',
        left: 304.9074074074074,
        top: 198.10185185185182,
        width: 417.9629629629629,
        height: 140,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 80px\'>感谢观看</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        wordSpace: 5
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'MZVO1kkj',
    elements: [
      {
        type: 'shape',
        id: 'cql0h8',
        left: 0,
        top: 0,
        width: 352.59259259259255,
        height: 562.5,
        viewBox: [200, 200],
        path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0
      },
      {
        type: 'shape',
        id: '_RTaF4',
        left: 171.4814814814814,
        top: 100.13888888888887,
        width: 362.22222222222223,
        height: 362.22222222222223,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: 'rgba(255,255,255,0)',
        fixedRatio: false,
        rotate: 0,
        outline: {
          width: 10,
          color: '{{backgroundColor}}',
          style: 'solid'
        }
      },
      {
        type: 'shape',
        id: 'UZfo8N',
        left: 216.66666666666663,
        top: 145.32407407407408,
        width: 271.85185185185185,
        height: 271.85185185185185,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'font-size: 80px\'>01</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      },
      {
        type: 'text',
        id: 'ysqtBg',
        left: 561.4814814814814,
        top: 100.1388888888889,
        width: 359.25925925925924,
        height: 80,
        content: '<p style=\'\'><strong><span style=\'font-size: 40px\'>在此处输入标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      },
      {
        type: 'text',
        id: 'lXsoHa',
        left: 572.5925925925925,
        top: 202.3611111111111,
        width: 257.77777777777777,
        height: 260,
        content: '<ol><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li><li><p style=\'\'>在此处输入内容</p></li></ol>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        lineHeight: 2,
        fill: '{{subColor}}'
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'EBBnTr',
        left: 360.5996472663139,
        top: 141.8496472663139,
        width: 278.80070546737215,
        height: 278.80070546737215,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 0,
          color: '{{backgroundColor}}',
          style: 'solid'
        }
      }, 
      {
        type: 'shape',
        id: 'gDIWDH',
        left: 456.4373897707231,
        top: 98.287037037037,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>1</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'DUWT7E',
        left: 317.037037037037,
        top: 237.68738977072314,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>4</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'pbhn38',
        left: 456.43738977072303,
        top: 377.08774250440916,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>3</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'shape',
        id: 'CvMKrO',
        left: 595.8377425044091,
        top: 237.6873897707231,
        width: 87.12522045855381,
        height: 87.12522045855381,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: true,
        rotate: 0,
        outline: {
          width: 4,
          color: '{{backgroundColor}}',
          style: 'solid'
        },
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>2</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'adudHB',
        left: 402.962962962963,
        top: 39.39814814814815,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: '9UpDwg',
        left: 402.962962962963,
        top: 473.1018518518518,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: 'GERdpB',
        left: 111.48148148148151,
        top: 256.25,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'text',
        id: 'G5qoho',
        left: 691.1111111111111,
        top: 256.25,
        width: 194.07407407407408,
        height: 50,
        content: '<p style=\'text-align: center;\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }, 
      {
        type: 'shape',
        id: 'vdZcI6',
        left: 415.18518518518516,
        top: 196.4351851851852,
        width: 169.62962962962962,
        height: 169.62962962962962,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'tYUmrx',
        left: 156.66666666666683,
        top: 149.02777777777771,
        width: 264.4444444444445,
        height: 264.4444444444445,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'><span style=\'font-size: 60px\'>01</span></span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      },
      {
        type: 'shape',
        id: '0GVHf8',
        left: 342.2222222222223,
        top: 217.17592592592587,
        width: 128.14814814814812,
        height: 128.14814814814812,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{backgroundColor}}',
        fixedRatio: false,
        rotate: 0
      },
      {
        type: 'text',
        id: 'BO33Sv',
        left: 378.8888888888889,
        top: 235.24999999999994,
        width: 464.4444444444444,
        height: 92,
        content: '<p style=\'\'><strong><span style=\'font-size: 48px\'>在此处添加标题</span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'Hj7ttp',
        left: 69.35185185185185,
        top: 49.21759259259262,
        width: 420,
        height: 63,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>1.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'FmKMNB',
        left: 69.35185185185185,
        top: 129.28240740740745,
        width: 420,
        height: 384,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'rI7ZeO',
        left: 510.64814814814815,
        top: 49.21759259259262,
        width: 420,
        height: 63,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 36px\'>2.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'KspwGc',
        left: 510.64814814814815,
        top: 129.28240740740745,
        width: 420,
        height: 384,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 24px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'Rx63Jo',
        left: 69.35185185185179,
        top: 51.71759259259262,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>1.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'ulyuzE',
        left: 69.35185185185179,
        top: 131.78240740740745,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'kr35Ca',
        left: 510.6481481481481,
        top: 51.71759259259262,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>2.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'BNQSpC',
        left: 510.6481481481481,
        top: 131.78240740740745,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'Vr38Nu',
        left: 69.35185185185185,
        top: 301.71759259259255,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>3.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'IwKRSu',
        left: 69.35185185185185,
        top: 381.7824074074074,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: '0Opr1v',
        left: 510.64814814814815,
        top: 301.71759259259255,
        width: 420,
        height: 58,
        lineHeight: 1.2,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 32px\'>4.请输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: '4L9Uzz',
        left: 510.64814814814815,
        top: 381.7824074074074,
        width: 420,
        height: 129,
        content: '<p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p><p style=\'text-align: center;\'><span style=\'font-size: 22px\'>在此处输入内容</span></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'text',
        id: 'GdEGxg',
        left: 134.53703703703704,
        top: 127.25,
        width: 152.77777777777777,
        height: 308,
        lineHeight: 1.8,
        content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 40px\'>请在此处输入标题</span></span></strong></p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        wordSpace: 8,
        fill: '{{themeColor}}',
      },
      {
        type: 'text',
        id: 'y5sAfw',
        left: 332.8703703703704,
        top: 127.25,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容1</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'VeuocM',
        left: 332.8703703703704,
        top: 212.0648148148148,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容2</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'RyFWQe',
        left: 332.8703703703704,
        top: 296.8796296296296,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容3</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
      {
        type: 'text',
        id: 'Q56viI',
        left: 332.8703703703704,
        top: 381.69444444444446,
        width: 532.5925925925926,
        height: 50,
        content: '<blockquote><p style=\'\'>请在此处输入内容4</p></blockquote>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}',
      },
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
  {
    id: 'template',
    elements: [
      {
        type: 'shape',
        id: 'SUWirT',
        left: 73.8888888888889,
        top: 64.21296296296302,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>1</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'YjzN1M',
        left: 148.70370370370372,
        top: 64.21296296296302,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'fS09I7',
        left: 527.5925925925926,
        top: 64.21296296296302,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>2</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'qCnfB1',
        left: 602.4074074074074,
        top: 64.21296296296302,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      },
      {
        type: 'shape',
        id: 'difAAT',
        left: 73.8888888888889,
        top: 221.25000000000003,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>3</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: 'EUlvMo',
        left: 148.70370370370372,
        top: 221.25000000000003,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'US_9jB',
        left: 527.5925925925926,
        top: 221.25000000000003,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>4</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '243MnQ',
        left: 602.4074074074074,
        top: 221.25000000000003,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'Y_KUj0',
        left: 73.8888888888889,
        top: 378.287037037037,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>5</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '9GglMe',
        left: 148.70370370370372,
        top: 378.287037037037,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }, 
      {
        type: 'shape',
        id: 'eSInje',
        left: 527.5925925925926,
        top: 378.287037037037,
        width: 49.629629629629626,
        height: 49.629629629629626,
        viewBox: [200, 200],
        path: 'M 100 0 A 50 50 0 1 1 100 200 A 50 50 0 1 1 100 0 Z',
        fill: '{{themeColor}}',
        fixedRatio: false,
        rotate: 0,
        text: {
          content: '<p style=\'text-align: center;\'><span style=\'color: #ffffff;\'>6</span></p>',
          defaultFontName: '{{fontName}}',
          defaultColor: '{{fontColor}}',
          align: 'middle'
        }
      }, 
      {
        type: 'text',
        id: '0S3yUg',
        left: 602.4074074074074,
        top: 378.287037037037,
        width: 323.7037037037037,
        height: 120,
        content: '<p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p><p style=\'\'>在此输入内容</p>',
        rotate: 0,
        defaultFontName: '{{fontName}}',
        defaultColor: '{{fontColor}}',
        fill: '{{subColor}}'
      }
    ],
    background: {
      type: 'solid',
      color: '{{backgroundColor}}',
    },
  },
]